import React from "react";
import PropTypes from "prop-types";
import SaveModal from "./SaveModal";

const CRUDModal = (props) => {
  const {
    action,
    fields,
    entityName,
    entity,
    onCancel,
    onSubmit,
    onDelete,
    updateModalFooter,
  } = props;
  if (action === "create" || action === "update") {
    return (
      <SaveModal
        action={action}
        entity={entity}
        fields={fields}
        onCancel={onCancel}
        onSubmit={onSubmit}
        entityName={entityName}
        onDelete={onDelete}
        updateModalFooter={updateModalFooter}
      />
    );
  }
  return <></>;
};

CRUDModal.propTypes = {
  action: PropTypes.string,
  fields: PropTypes.array.isRequired,
  entityName: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateModalFooter: PropTypes.func,
};

export default CRUDModal;
