import React from "react";
import { useRouteError } from "react-router-dom";
import Layout from "./Layout";

const ErrorView = (props) => {
  const error = useRouteError();
  const { status } = error;
  const notFound = status === 404;
  return <Layout notFound={notFound} error={error} />;
};

export default ErrorView;
