import React from "react";
import CRUD from "../components/common/CRUD/CRUD";
import { createCRUDService } from "../services/crudService";
import { fields } from "../models/accountModel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
  "/api/admin/accounts"
);

export default function AccountsView() {
  return (
    <main style={{ padding: "1rem 0" }}>
      <CRUD
        fields={fields}
        entityName={"cuenta"}
        showAllInList={true}
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
        updateModalFooter={(account) => {
          return (
            <Container key="update_children" className="pt-3">
              <Row>
                <Col>
                  <Link to={`/accounts/${account.id}/modules`}>
                    Módulos y Submodulos
                  </Link>
                </Col>
              </Row>
            </Container>
          );
        }}
      />
    </main>
  );
}
