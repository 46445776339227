import { parseField } from "../components/common/CRUD/Field";

export const fields = [
  parseField({
    name: "id",
    type: "number",
    insertDisabled: true,
    editDisabled: true,
  }),
  parseField({
    name: "Nombre",
    property: "name",
  }),
];
