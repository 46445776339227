import React from "react";
import Filter from "./Filter";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

const EntityTableFilters = (props) => {
  const { onFilterChange, filterParams } = props;
  return (
    <>
      <Row>
        {filterParams.map((filter, index) => (
          <Col key={`filter-${index}`}>
            <Filter onFilterChange={onFilterChange} filter={filter} />
          </Col>
        ))}
      </Row>
    </>
  );
};

EntityTableFilters.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filterParams: PropTypes.array.isRequired
}

export default EntityTableFilters;
