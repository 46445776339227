import axios from "axios";
import { getToken, setToken } from "./session";

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const {
      config: { url, method },
      response: { status },
    } = error;

    if (url === "/api/auth" && method === "post") return Promise.reject(error);
    const forbiddenStatus = [403];
    if (forbiddenStatus.includes(status)) {
      setToken();
      window.location = "/";
    }
    return Promise.reject(error);
  }
);

export const getAxios = () => {
  const token = getToken();
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios;
};
