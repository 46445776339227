import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";

const Header = () => {
  return (
    <>
      <Navbar bg="light" expand="md" style={{ padding: ".5em" }}>
        <Navbar.Brand>
          <Link to="/" className={"navbar-brand"}>
            Admin
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Cuentas">
              <NavDropdown.Item as="div">
                <Link to={`/accounts`} className={"nav-link"}>
                  Cuentas
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/logout">Cerrar Sesión</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
