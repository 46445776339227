import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AccountModulesService from "../services/accountModulesService";
import AccountsModulesList from "../components/accounts/AccountsModulesList";

export default function AccountModulesView() {
  const { accountId } = useParams();
  const [state, setState] = useState({ refresh: 0 });
  const { accountModules, error, refresh } = state;
  const triggerRefresh = () =>
    setState((s) => ({ ...s, refresh: refresh + 1 }));

  useEffect(() => {
    AccountModulesService.getAccountModules(accountId, refresh)
      .then((response) =>
        setState((s) => ({ ...s, accountModules: response.data }))
      )
      .catch((error) => setState((s) => ({ ...s, error })));
  }, [accountId, refresh]);

  const onModuleUpdate = (module) => {
    return AccountModulesService.updateModule(
      accountId,
      module,
      module.hasModule
    )
      .then(() => triggerRefresh())
      .catch((error) => setState((s) => ({ ...s, error })));
  };

  const onSubmoduleUpdate = (submodule) => {
    return AccountModulesService.updateSubmodule(
      accountId,
      submodule,
      submodule.hasSubmodule
    )
      .then(() => triggerRefresh())
      .catch((error) => setState((s) => ({ ...s, error })));
  };

  return (
    <main style={{ padding: "1rem 0" }}>
      <Container>
        <Row>
          {accountModules && (
            <AccountsModulesList
              modules={accountModules}
              onModuleUpdate={onModuleUpdate}
              onSubmoduleUpdate={onSubmoduleUpdate}
            />
          )}
        </Row>
        <Row>
          <Col>
            Error: <pre>{error && JSON.stringify(error, undefined, 2)}</pre>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
