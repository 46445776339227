import React from "react";
import PropTypes from "prop-types";

const FetchMessage = (props) => {
  const { isFetching, plural } = props
  if (isFetching) {
    return (
      <div style={{ float: 'right' }}>{`Cargando ${plural}...`}</div>
    )
  }
  return <span></span>
}

FetchMessage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  plural: PropTypes.string.isRequired
}

export default FetchMessage