import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import CRUDModal from "./CRUDModal";
import { capitalize } from "../../../utils/strings";
import FetchMessage from "./FetchMessage";
import { getDefaultEntity } from "../../../utils/entities";
import EntityTableFilters from "./EntityTableFilters";
import OptionalComponent from "../OptionalComponent";
import EntitiesList from "./EntitiesList";

function resetStateFactory(defaultState, setState) {
  return function (overrides = {}) {
    setState({ ...defaultState, ...overrides });
  };
}

const defaultState = {
  entity: {},
  paginationParams: { limit: 50, offset: 0 },
  isFetching: false,
  action: null,
  reload: false,
  data: { rows: [] },
};

const CRUD = (props) => {
  const {
    fields,
    filters = [],
    entityName,
    entityNamePlural,
    customTableTitle,
    dataSource,
    onCreate,
    onUpdate,
    onDelete,
    listTitle,
    showAllInList = false,
    onClick,
    createButtonLabel,
    updateModalFooter = [],
  } = props;
  const [state, setState] = useState({
    ...defaultState,
    filterParams: filters,
  });
  const {
    action,
    entity,
    isFetching,
    data,
    paginationParams,
    filterParams,
    reload,
  } = state;

  const resetStateFunction = resetStateFactory(
    { ...defaultState, paginationParams, filterParams },
    setState
  );
  const parseFilterValue = (currentFilter) => {
    const { filterType, filterValue, operator, targetField } = currentFilter;
    let parsedValue = filterValue;
    if (filterType === "date" && filterValue) {
      parsedValue = filterValue.toISOString().slice(0, 10);
    }
    return `${targetField}:${operator}:${parsedValue}`;
  };
  const refreshData = (pagination, filterParams = []) => {
    const filters = filterParams.reduce((allFilters, currentFilter) => {
      let parsedValue = parseFilterValue(currentFilter);
      allFilters.push(parsedValue);
      return allFilters;
    }, []);
    setState((s) => ({ ...s, isFetching: true }));
    dataSource({ ...pagination, filters })
      .then((data) => {
        const newState = {
          isFetching: false,
          data: Array.isArray(data) ? { rows: data } : data,
        };
        setState((s) => ({ ...s, ...newState }));
      })
      .catch((ex) => {
        console.log("ex", ex);
        console.error("Error loading dataSource in CRUD component");
      });
  };
  const refreshDataCallback = useCallback(refreshData, [dataSource]);

  useEffect(() => {
    refreshDataCallback(paginationParams, filterParams);
  }, [paginationParams, filterParams, refreshDataCallback, reload]);

  const showEdit = (entity) => {
    resetStateFunction({
      action: "update",
      entity: entity,
      data,
    });
  };

  const onClickHandler = onClick ? onClick : showEdit;

  const showCreate = () => {
    resetStateFunction({
      action: "create",
      entity: getDefaultEntity(fields),
      data,
    });
  };

  const onCreateOrUpdateHandler = async (entity, action) => {
    if (action === "create") await onCreate(entity);
    if (action === "update") await onUpdate(entity);
    resetStateFunction({ reload: !state.reload, data });
  };

  const onDeleteHandler = async (entity) => {
    await onDelete(entity);
    resetStateFunction({ reload: !state.reload, data });
  };

  const onCancelHandler = () => {
    resetStateFunction({ data });
  };

  const onFilterChange = (target, value) => {
    setState({
      ...state,
      filterParams: state.filterParams.map((filter) => {
        let { filterBy, filterValue } = filter;
        if (filterBy === target) filterValue = value;
        return {
          ...filter,
          filterValue,
        };
      }),
    });
  };

  const plural = entityNamePlural || `${entityName}s`;
  const tableTitle = customTableTitle || plural;
  const createButtonText = createButtonLabel || `Crear nuevo ${entityName}`;
  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>{capitalize(tableTitle)}</h2>
      <Button style={{ marginBottom: "1em" }} onClick={showCreate}>
        {createButtonText}
      </Button>
      <br />
      <OptionalComponent showComponent={filterParams.length > 0}>
        <EntityTableFilters
          onFilterChange={onFilterChange}
          filterParams={filterParams}
        />
        <br />
      </OptionalComponent>

      <EntitiesList
        data={data}
        subTitleProp="client.name"
        onClick={onClickHandler}
        titleProp={listTitle || "name"}
        showAllInList={showAllInList}
        fields={fields}
      />

      <FetchMessage isFetching={isFetching} plural={plural} />

      <CRUDModal
        action={action}
        entity={entity}
        entityName={entityName}
        entityNamePlural={entityNamePlural}
        fields={fields}
        onSubmit={onCreateOrUpdateHandler}
        onDelete={onDeleteHandler}
        onCancel={onCancelHandler}
        updateModalFooter={updateModalFooter}
      />
    </main>
  );
};

CRUD.propTypes = {
  fields: PropTypes.array.isRequired,
  filters: PropTypes.array,
  listTitle: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  entityNamePlural: PropTypes.string,
  customTableTitle: PropTypes.string,
  paginated: PropTypes.bool,
  dataSource: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  extraActions: PropTypes.array,
  showAllInList: PropTypes.bool,
  createButtonLabel: PropTypes.string,
  updateModalFooter: PropTypes.func,
};

export default CRUD;
