import React from "react";
import { Container } from "react-bootstrap";

export default function HomeView() {
  return (
    <Container>
      <div>
        <h3>Bienvenido a e-nventario.com</h3>
      </div>
    </Container>
  );
}
