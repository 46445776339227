import Form from "react-bootstrap/Form";
import { capitalize } from "../../../utils/strings";
import React from "react";
import PropTypes from "prop-types";

const FormControl = (props) => {
  const { field, fieldValue, disabled, onChange, title } = props;
  const { name, type, placeHolder, property } = field;
  const isTextArea = type === "textarea";
  const handleChange = ({ target: { value } }) => {
    if (!disabled) {
      onChange(property, value);
    }
  };
  return (
    <>
      <Form.Label>{title || capitalize(name)}</Form.Label>
      <Form.Control
        disabled={disabled}
        type={type}
        placeholder={placeHolder}
        value={fieldValue}
        onChange={handleChange}
        as={isTextArea ? "textarea" : "input"}
        rows={isTextArea ? 5 : undefined}
      />
    </>
  );
};

FormControl.propTypes = {
  field: PropTypes.object.isRequired,
  fieldValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

export default FormControl;
