import React, { useState } from "react";
import FormControl from "./FormControl";
import DatePicker from "./DatePicker";
import PropTypes from "prop-types";

const Filter = (props) => {
  const { filter, onFilterChange } = props;
  const [state, setState] = useState(filter);
  const { filterBy, filterType, filterValue, render } = state;

  const onChange = (name, value) => {
    setState({
      ...state,
      filterValue: value,
    });
    onFilterChange(name, value);
  };

  const field = {
    name: filterBy,
    value: filterValue,
  };
  if (filterType === "date") {
    const entity = { [filterBy]: filterValue };
    return (
      <DatePicker
        field={field}
        onChange={onChange}
        disabled={false}
        entity={entity}
      />
    );
  } else if (filterType === "custom") {
    return render(onChange);
  }
  return (
    <FormControl
      onChange={onChange}
      disabled={false}
      fieldValue={filterValue}
      field={field}
    />
  );
};

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired
}

export default Filter;