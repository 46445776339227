import React from "react";
import Layout from "./views/Layout";
import ErrorView from "./views/ErrorView";
import AccountsView from "./views/AccountsView";
import AccountModulesView from "./views/AccountModulesView";
import LogoutView from "./views/LogoutView";

const routes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorView />,
    children: [
      {
        path: "/accounts",
        element: <AccountsView />,
      },
      {
        path: "/accounts/:accountId/modules",
        element: <AccountModulesView />,
      },
    ],
  },
  {
    path: "/logout",
    element: <LogoutView />,
  },
];

export default routes;
