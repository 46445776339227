import React from "react";
import { Outlet, useMatches } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/system/Header";
import HomeView from "./HomeView";
import OptionalComponent from "../components/common/OptionalComponent";
import Breadcrumbs from "../components/system/Breadcrumbs";
import PropTypes from "prop-types";
import { FaRegGrimace } from "react-icons/fa";

const getNotFoundError = (data = "") => {
  const path = data.split('"')[1];
  return `No se encontró la ruta ${path}!`;
};

const ErrorContainer = (props) => {
  const { notFound, error } = props;
  const errorMessage = notFound
    ? getNotFoundError(error.data)
    : `${error.message}\n${error.stack}`;
  return (
    <Container className="pt-md-5">
      <Row>
        <Col md={6} className="text-center">
          <FaRegGrimace size={200} />
        </Col>
        <Col md={6} className="text-start">
          <pre>{errorMessage}</pre>
        </Col>
      </Row>
    </Container>
  );
};

ErrorContainer.propTypes = {
  error: PropTypes.any,
  notFound: PropTypes.bool,
};

const Layout = (props) => {
  const { error, notFound } = props;
  const matches = useMatches();
  const [layoutRoute, nestedRoute] = matches;
  const isHome = layoutRoute.pathname === "/" && nestedRoute === undefined;

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs />
        <Outlet />
        <OptionalComponent showComponent={isHome && error === undefined}>
          <HomeView />
        </OptionalComponent>
        <OptionalComponent showComponent={error !== undefined}>
          <ErrorContainer notFound={notFound} error={error} />
        </OptionalComponent>
      </Container>
    </>
  );
};

Layout.propTypes = {
  error: PropTypes.any,
  notFound: PropTypes.bool,
};

export default Layout;
