function getToken() {
  return localStorage.getItem("admin_token");
}

function setToken(token = "") {
  return localStorage.setItem("admin_token", token);
}

module.exports = {
  getToken,
  setToken,
};
