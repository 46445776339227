import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { EntityFields } from "./EntityFields";
import Button from "react-bootstrap/Button";
import { capitalize } from "../../../utils/strings";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import OptionalComponent from "../OptionalComponent";
import PropTypes from "prop-types";
import { FaTrash } from "react-icons/fa";

const styles = {
  button: { minWidth: "80px", marginLeft: "1em" },
  formOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 99,
    opacity: 0.5,
    backgroundColor: "#808080",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
};

const parseEntityProp = (value) => {
  if (typeof value === "object") return JSON.stringify(value);
  return value;
};

function getEntityDetails(entity) {
  const keys = Object.keys(entity);
  return keys.map((key) => {
    return (
      <div key={`${key}-value`}>
        {key}: {parseEntityProp(entity[key])}
      </div>
    );
  });
}

const getTitle = (isCreate, entityName) => {
  const name = capitalize(entityName);
  if (isCreate) return `Crear un nuevo ${name}`;
  const vowels = ["a", "e", "i", "o", "u"];
  const article = vowels.includes(entityName[0]) ? "an" : "a";
  return `Update ${article} ${entityName}`;
};

const SaveModal = (props) => {
  const {
    action,
    entity,
    fields,
    onCancel,
    onSubmit,
    entityName,
    onDelete,
    updateModalFooter,
  } = props;
  const isCreate = action === "create";

  const isUpdate = action === "update";

  let submitButtonText = "Guardar";
  const [state, setState] = useState({ doDelete: false });
  const { errors = [], isLoading = false, doDelete } = state;
  const [currentEntity, setCurrentEntity] = useState({ ...entity });

  if (doDelete) submitButtonText = "Borrar";

  const title = getTitle(isCreate, entityName);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (doDelete) return onDelete(currentEntity);
    setState({ ...state, isLoading: true });
    onSubmit(currentEntity, action).catch((err) => {
      const { request } = err;
      let errors = ["Unexpected Error"];
      if (request && request.response) {
        try {
          const parsedResponse = JSON.parse(request.response);
          if (Array.isArray(parsedResponse)) {
            errors = parsedResponse.map(
              (responseError) => responseError.message
            );
          } else {
            errors = [parsedResponse];
          }
        } catch (ex) {
          errors = [request.response];
        }
      }
      setState({ errors, isLoading: false });
    });
  };

  const handleHide = (event) => {
    if (!isLoading) {
      onCancel();
    }
  };

  const handleCancel = (event) => {
    if (doDelete) {
      return setState((s) => ({ ...s, doDelete: false }));
    }
    return handleHide(event);
  };

  const handleDelete = () => {
    setState((s) => ({ ...s, doDelete: true }));
  };

  return (
    <Modal
      show={true}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          {!doDelete && (
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
              {!isCreate && (
                <Col>
                  <Button
                    onClick={handleDelete}
                    variant="danger"
                    style={{ position: "absolute", right: 50, top: 15 }}
                  >
                    <FaTrash />
                  </Button>
                </Col>
              )}
            </Modal.Title>
          )}
          {doDelete && (
            <>
              <Modal.Title> Confirmar eliminación </Modal.Title>
            </>
          )}
        </Modal.Header>
        <Modal.Body tyle={{ position: "relative", backgroundColor: "blue" }}>
          <OptionalComponent showComponent={isLoading}>
            <div style={styles.formOverlay}></div>
          </OptionalComponent>
          {!doDelete && (
            <>
              <EntityFields
                fields={fields}
                isCreate={isCreate}
                entity={currentEntity}
                onChange={(field, newValue) => {
                  const newEntity = { ...currentEntity };
                  newEntity[field] = newValue;
                  setCurrentEntity(newEntity);
                }}
              />
              {isUpdate &&
                updateModalFooter &&
                updateModalFooter(currentEntity)}
            </>
          )}
          {doDelete && (
            <>
              <h3>¿Realmente desea eliminar lo siguiente? {entityName}?</h3>
              <h2 style={{ color: "red" }}>Esto no se puede deshacer</h2>
              {getEntityDetails(entity)}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Container>
            {errors.map((err, index) => {
              return (
                <Row key={`save-modal-error-${index}`}>
                  <Col>
                    <Alert variant="danger">{err}</Alert>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  onClick={handleCancel}
                  style={styles.button}
                >
                  Cancelar
                </Button>
                <Button variant="success" type="submit" style={styles.button}>
                  {submitButtonText}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SaveModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  entity: PropTypes.object.isRequired,
  updateModalFooter: PropTypes.func,
};

export default SaveModal;
