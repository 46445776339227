import React from "react";
import { get } from "lodash";
import ListGroup from "react-bootstrap/ListGroup";
import PropTypes from "prop-types";

const getFieldsValues = (fields, entity) => {
  let text = "";
  let separator = "";
  for (const field in fields) {
    const fieldName = fields[field].name;
    const fieldProp = fields[field].property;
    text = `${text}${separator} ${fieldName}: ${entity[fieldProp]}`;
    separator = ", ";
  }
  return text;
};

const EntitiesList = (props) => {
  const { data, titleProp, showAllInList = false, fields } = props;
  const { rows } = data;
  return (
    <>
      <ListGroup as="ol" numbered>
        {rows.map((row, index) => {
          const title = get(row, titleProp, "");
          return (
            <ListGroup.Item
              as="li"
              key={`entry-list-item-${index}`}
              className="d-flex align-items-start mb-2"
              onClick={() => props.onClick(row)}
            >
              <div className="ms-2 me-auto">
                <div>
                  {showAllInList ? getFieldsValues(fields, row) : title}
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
};

EntitiesList.propTypes = {
  titleProp: PropTypes.string.isRequired,
  data: PropTypes.shape({
    rows: PropTypes.array,
  }),
  onClick: PropTypes.func.isRequired,
  showAllInList: PropTypes.bool,
  fields: PropTypes.any,
};

export default EntitiesList;
