/**
 * Warning! This is a global state store, this means
 * that if you abuse this functionality, all of your
 * components will be tightly coupled to this state
 * making it harder to reuse components!
 *
 * DO NOT ABUSE! USE ONLY WHEN NEEDED!
 */

import React from "react";

const initialState = {
  user: { email: "" },
  userModules: {},
};

const appContext = React.createContext(initialState);
export default appContext;
