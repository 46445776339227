import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";

const AccountsModulesList = (props) => {
  const { modules = [], onModuleUpdate, onSubmoduleUpdate } = props;
  return (
    <Container fluid>
      <Row>
        {modules.map((module, index) => {
          return (
            <Col key={`account_module_${index}`}>
              <ul>
                <li>
                  {module.description}{" "}
                  <input
                    type="checkbox"
                    checked={module.hasModule !== 0}
                    onChange={() => onModuleUpdate(module)}
                  />
                  {module.submodules && Array.isArray(module.submodules) && (
                    <ul>
                      {module.submodules.map((submodule) => {
                        return (
                          <li key={`account_submodule_${submodule.id}`}>
                            {submodule.description}{" "}
                            <input
                              type="checkbox"
                              checked={submodule.hasSubmodule !== 0}
                              onChange={() => onSubmoduleUpdate(submodule)}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              </ul>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

AccountsModulesList.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      ordinal: PropTypes.number.isRequired,
      hasModule: PropTypes.number.isRequired,
      submodules: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          system_module_id: PropTypes.number.isRequired,
          path: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          ordinal: PropTypes.number.isRequired,
          hasSubmodule: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  onModuleUpdate: PropTypes.func.isRequired,
  onSubmoduleUpdate: PropTypes.func.isRequired,
};

export default AccountsModulesList;
