import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginView from "./views/LoginView";
import { getToken } from "./utils/session";
import routes from "./routes";
import { getSessionInfo } from "./services/session";
import AppContext from "./AppContext";
const router = createBrowserRouter(routes);

const MainApp = () => {
  const [state, setState] = useState({});
  const { user, userModules } = state;

  useEffect(() => {
    // No catch needed because we have that in an Axios interceptor
    getSessionInfo()
      .then((response) => {
        const { data } = response;
        const { user } = data;
        return setState((s) => ({
          ...s,
          user,
        }));
      })
      .catch((err) => {
        setState((s) => ({ ...s, error: err }));
      });
  }, []);

  return (
    <AppContext.Provider value={{ user, userModules }}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
};

const App = () => {
  const token = getToken();
  if (!token) return <LoginView />;
  return <MainApp />;
};

export default App;
