import { getAxios } from "../utils/requests";
const axios = getAxios();

export default class AccountModulesService {
  static getAccountModules(accountId) {
    return axios.get(`/api/admin/accounts/${accountId}/modules`);
  }

  static updateModule(accountId, module, hasModule) {
    const newValue = hasModule === 0 ? 1 : 0;
    return axios.put(`/api/admin/accounts/${accountId}/modules`, {
      ...module,
      hasModule: newValue,
    });
  }

  static updateSubmodule(accountId, submodule, hasSubmodule) {
    const newValue = hasSubmodule === 0 ? 1 : 0;
    return axios.put(`/api/admin/accounts/${accountId}/submodules`, {
      ...submodule,
      hasSubmodule: newValue,
    });
  }
}
