import React from "react";
import { setToken } from "../utils/session";
import LoginView from "./LoginView";

const LogoutView = () => {
  setToken("");
  return <LoginView />;
};

export default LogoutView;
