import React from "react";
import PropTypes from "prop-types";
import OptionalComponent from "../common/OptionalComponent";
import Alert from "react-bootstrap/Alert";

const ErrorMessage = (props) => {
  const { showError, error = "" } = props;
  return (
    <OptionalComponent showComponent={showError}>
      <Alert variant="danger">{error}</Alert>
    </OptionalComponent>
  );
};

ErrorMessage.propTypes = {
  showError: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default ErrorMessage;
