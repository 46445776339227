import React from "react";
import FormControl from "./FormControl";
import DatePicker from "./DatePicker";
import Select from "./Select";

function getIsDisabled({ insertDisabled, editDisabled }, isCreate) {
  if (isCreate) {
    return insertDisabled === true;
  } else {
    return editDisabled === true;
  }
}

const getFieldValue = (fieldName, entity) => {
  return entity[fieldName];
};

const typeFactory = {
  date: ({ field, entity, onChange, disabled }) => {
    return (
      <DatePicker
        field={field}
        disabled={disabled}
        entity={entity}
        onChange={onChange}
      />
    );
  },
  custom: (fieldData) => {
    const { field, disabled } = fieldData;
    return field.renderEdit(fieldData, disabled);
  },
  select: ({ field, onChange, fieldValue, disabled }) => {
    return (
      <Select
        onChange={onChange}
        disabled={disabled}
        defaultValue={fieldValue}
        options={field.options}
        fieldName={field.name}
        property={field.property}
      />
    );
  },
};

export const getFieldControl = (fieldData) => {
  const { field, isCreate, entity, readOnly = false, onChange } = fieldData;
  const { property, type } = field;
  const disabled = readOnly === true || getIsDisabled(field, isCreate);
  const fieldValue = getFieldValue(property, entity);

  if (typeFactory[type]) {
    return typeFactory[type]({ ...fieldData, disabled, fieldValue });
  }

  return (
    <FormControl
      field={field}
      onChange={onChange}
      disabled={disabled}
      fieldValue={fieldValue}
    />
  );
};
