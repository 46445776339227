import { getFieldControl } from "./FieldControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

export const EntityFields = (props) => {
  const { fields = [], isCreate, entity, onChange, readOnly } = props;
  return fields.map((field, index) => {
    return (
      <Row key={`modal-field-${index}`}>
        <Col>
          {getFieldControl({ field, isCreate, entity, onChange, readOnly })}
        </Col>
      </Row>
    );
  });
};
